import { StringUtils } from './string-utils';

// Returns timezone aware 'date' into a 'YYYY-MM-DD' string.
// Time offset is removed before conversion (is TZ or UTC).
// References:
// https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd

export const toISOLocalDateString = (date) => {
    if (date == null) {
        return null;
    }
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().substring(0, 10);
};

export const toISODateString = (date) => {
    return date == null ? null : date.toISOString().substring(0, 10);
};

export const toISOMonthString = (date, emptyValue = null) => {
    return date == null ? emptyValue : date.toISOString().substring(0, 7);
};

export const toMonthYearString = (date, emptyValue = null) => {
    return date == null ? emptyValue : toMonthYearFromISOString(date.toISOString(), emptyValue);
};

export const toMonthYearFromISOString = (isoString, emptyValue = null) => {
    return isoString == null
        ? emptyValue
        : `${isoString.substring(5, 7)}/${isoString.substring(0, 4)}`;
};

export const localDateNow = () => {
    return toISOLocalDateString(new Date());
};

/**
 *
 * @param {*} isoLocalDateString: e.g. '1973-12-24'
 * @returns Date object '1973-12-24' at zero hour in the browsers' UTC offset
 */

export const fromISOLocalDateString = (isoLocalDateString) => {
    if (StringUtils.isEmpty(isoLocalDateString)) {
        return null;
    }
    const dateParts = isoLocalDateString.split('-');
    return new Date(dateParts[0], parseInt(dateParts[1]) - 1, dateParts[2]);
};

// https://stackoverflow.com/a/38050824

export const midnightStart = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const midnightStartToday = () => {
    return midnightStart(new Date());
};

export const midnightStartTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return midnightStart(tomorrow);
};

export const firstDayOfMonth = (refDate) => {
    return refDate == null ? null : new Date(refDate.getFullYear(), refDate.getMonth(), 1);
};

export const lastDayOfMonth = (refDate) => {
    return refDate == null ? null : new Date(refDate.getFullYear(), refDate.getMonth() + 1, 0);
};

const SUNDAY_DAY = 0;
const MONDAY_DAY = 1;

export const firstDayOfWeek = (refDate) => {
    if (refDate == null) return null;
    const refDay = refDate.getDay();
    const newDate = refDate.getDate() - refDay + (refDay === SUNDAY_DAY ? -6 : MONDAY_DAY);
    return new Date(refDate.getFullYear(), refDate.getMonth(), newDate);
};

export const lastDayOfWeek = (refDate) => {
    if (refDate == null) return null;
    const refDay = refDate.getDay();
    const newDate = refDate.getDate() - refDay + (refDay === SUNDAY_DAY ? SUNDAY_DAY : 7);
    return new Date(refDate.getFullYear(), refDate.getMonth(), newDate);
};

export const isFutureDate = (refDate) => {
    if (refDate == null) return false;
    const midnight = midnightStartTomorrow();
    return Boolean(midnight <= refDate);
};

export const zeroMinutesNow = () => {
    const now = new Date();
    now.setMinutes(0, 0, 0);
    return now;
};

/**
 * True if both date have the same getTime or both are null.
 */
export const sameDate = (date1, date2) => {
    return date1?.getTime() === date2?.getTime();
};

const SITE_TIME_ZONE = 'America/Argentina/Buenos_Aires';

export const toMidnightTemporalInSiteTimeZone = (localDate) => {
    const result = Temporal.ZonedDateTime.from({
        year: localDate.getFullYear(),
        // Temporal.month is NOT zero based
        month: localDate.getMonth() + 1,
        day: localDate.getDate(),
        hour: 0,
        minute: 0,
        second: 0,
        timeZone: SITE_TIME_ZONE,
    });
    return result;
};

export const toISODateTimeStringWithoutTimeZoneName = (temporal) => {
    // Use toString to get the full string with timezone info
    const isoStringWithTimeZone = temporal.toString(); // e.g., "2025-02-10T00:00:00-03:00[America/Argentina/Buenos_Aires]"

    // Extract just the date and offset part
    const isoStringWithoutTimeZoneName = isoStringWithTimeZone.replace(/\[.*\]$/, '');

    return isoStringWithoutTimeZoneName;
};
