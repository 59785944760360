import { useCallback, useEffect, useState } from 'react';

import { generatePath, useHistory } from 'react-router';

import { useForm } from 'react-hook-form';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { ClearFilterButton, RefreshButton } from '@components/buttons';
import { PersonField } from '@components/fields';
import { DEFAULT_SELECTOR_OPTION, SinceUntilBlock } from '@components/fields/sinceUntilBlock';
import { PersonFormalNameLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { sameDate } from '@utils/date-utils';
import { sameModels } from '@utils/iterable-utils';
import { isoLocalDateTimeTemplate } from '@utils/money';
import { appClickOpenPush } from '@utils/nav-utils';

import { TokenTypeField } from '@components/fields/tokenType';
import { TokenService } from '@services/tokenService';
import { TokensPath } from '@tokens/routes';
import { packDescription } from '@tokens/templates';
import { TokenPackBalance } from '@tokens/uses/create';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

const defaultSearchConfig = () => {
    return {
        typeId: null,
        persons: [],
        ...DEFAULT_SELECTOR_OPTION.compute(),
    };
};

const defaultInitialValues = () => {
    return {
        ...defaultSearchConfig(),
        period: DEFAULT_SELECTOR_OPTION,
    };
};

const changedConfig = (config1, config2) => {
    return [
        config1.typeId !== config2.typeId,
        !sameModels(config1.persons, config2.persons),
        !sameDate(config1.since, config2.since),
        !sameDate(config1.until, config2.until),
    ].some((item) => item);
};

export const TokenPackSearch = () => {
    const history = useHistory();
    const [service] = useState(new TokenService());
    const { control, watch, reset, setValue } = useForm({
        defaultValues: defaultInitialValues(),
    });

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Params: SearchConfig
    const [searchConfig, setSearchConfig] = useState(defaultSearchConfig());

    // Search results
    const [items, setItems] = useState([]);
    const [requestErrors, setRequestErrors] = useState();

    const [persons, typeId, period, since, until] = watch([
        'persons',
        'typeId',
        'period',
        'since',
        'until',
    ]);

    // Callbacks --------------------------------------------------------------

    // search can be called:
    // - On demand: repeatSearch
    // - On change: searchConfig
    // - On change: pagination (rows/page)
    // When searchConfig changes, pagination hast to change too: Page == 0

    const search = useCallback(
        (searchConfig, first, page, rows) => {
            const searchParams = {
                pageSize: rows,
                page: page + 1,
                ...searchConfig,
            };
            setRequestErrors(null);
            service
                .packs(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        // Correct this here, so errors can´t happen
        if (since != null && until != null && until < since) {
            setValue('until', since);
            return;
        }
        const newSearchConfig = { persons, typeId, since, until };
        if (changedConfig(searchConfig, newSearchConfig)) {
            setSearchConfig(newSearchConfig);
        }
    }, [searchConfig, persons, typeId, since, until, setValue]);

    useEffect(() => {
        if (period && Object.prototype.hasOwnProperty.call(period, 'compute')) {
            const newRange = period.compute();
            setValue('since', newRange.since);
            setValue('until', newRange.until);
        }
    }, [period, setValue]);

    useEffect(() => {
        search(searchConfig, 0, 0, rows);
    }, [search, searchConfig, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(searchConfig, event.first, event.page, event.rows);
    };

    const repeatSearch = () => {
        search(searchConfig, first, page, rows);
    };

    const clearFilters = () => {
        setSearchConfig(defaultSearchConfig());
        reset(defaultInitialValues());
    };

    const rowClick = ({ data, originalEvent }) => {
        // console.log(`rowClick - target: ${originalEvent.target.className}`);
        // If the original target is the TokenUse modal dialog mask, ignore it
        // and do not execute any navigation. See code there, we were unable to
        // prevent onClick from bubbling up here when the mask was clicked.
        const target = originalEvent.target.className;
        if (target.includes('p-dialog-mask')) return;
        const url = generatePath(TokensPath.TokenPack.item, { id: data.id });
        appClickOpenPush(url, originalEvent, history);
    };

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="search-filter p-fluid">
            <PersonField
                control={control}
                fieldName="persons"
                fieldLabel="Persona(s)"
                fieldClassName="person"
                required={false}
                single={false}
            />
            <TokenTypeField
                control={control}
                required={false}
                // ----------------------------------
                className="type-selector"
                // Afford deselecting => implicit all
                placeholder="Todos"
                showClear
            />
            <SinceUntilBlock periodLabel="Generados" control={control} />
            <div className="flex-grow-1" />
            <div>
                <ClearFilterButton onClick={clearFilters} />
                <RefreshButton onRefresh={repeatSearch} />
            </div>
        </div>
    );

    const rowClassName = (pack) => (pack.isRevoked ? 'revoked' : '');

    const leftColumn = (pack) => (
        <div className="token-pack-row">
            <div className="pack-description"> {packDescription(pack)} </div>
            <div className="time-period">{pack.timePeriod}</div>
            <PersonFormalNameLink person={pack.person} aspect="tokens" />
            <div className="issued-at">{isoLocalDateTimeTemplate(pack, 'issuedAt')}</div>
        </div>
    );

    const rightColumn = (pack) => (
        <TokenPackBalance
            pack={pack}
            balanceLabel={'Disponibles'}
            // style={{ justifyContent: 'center' }}
            onChanged={repeatSearch}
        />
    );

    return (
        <div className="token-pack-search">
            <RequestMessages messages={requestErrors} />
            <DataTable
                value={items}
                dataKey="id"
                header={header}
                className="hide-columns-header"
                emptyMessage="No hay tokens generados"
                rowClassName={rowClassName}
                lazy
                // --------------------------------------------------------------------------
                // TODO: Implement an event-less navigation option so that right-click works
                selectionMode="single"
                onRowClick={rowClick}
                // --------------------------------------------------------------------------
                // Always displayed for this view, regardless of displayed records count
                // paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                paginator
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column body={leftColumn} />
                <Column body={rightColumn} />
            </DataTable>
        </div>
    );
};
