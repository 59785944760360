import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { FinalStep } from './FinalStep';
import { Step } from './Step';
import { WizardProvider } from './WizardContext';

// Source: https://chatgpt.com/c/678ab1b7-ce28-8005-92b2-290078698594

export const Wizard = () => {
    const { path } = useRouteMatch();

    return (
        <WizardProvider>
            <Switch>
                {/* Step 1 */}
                <Route
                    exact
                    path={`${path}`}
                    component={() => <Step step={1} nextStep={`${path}/step2`} />}
                />

                {/* Step 2 */}
                <Route path={`${path}/step2`}>
                    <Step step={2} nextStep={`${path}/step3`} prevStep={`${path}`} />
                </Route>

                {/* Step 3 */}
                <Route path={`${path}/step3`}>
                    <Step step={3} nextStep={`${path}/final`} prevStep={`${path}/step2`} />
                </Route>

                {/* Final Step - Guarded */}
                <Route path={`${path}/final`} component={FinalStep} />
            </Switch>
        </WizardProvider>
    );
};
