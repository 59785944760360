// https://www.npmjs.com/package/react-app-polyfill
import 'react-app-polyfill/ie11';
// https://github.com/tc39/proposal-temporal/tree/main/#polyfills
// https://www.npmjs.com/package/temporal-polyfill
import 'temporal-polyfill/global';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { HashRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { ProvideAuth } from '@hooks/use-auth.js';
import { AppWrapper } from './AppWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <StrictMode>
        <HashRouter>
            <ProvideAuth>
                <AppWrapper />
            </ProvideAuth>
        </HashRouter>
    </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
