import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { hookFormRequestError } from '@custom/hook-form';

import { Button } from 'primereact/button';

import { ModalForm } from '@components/forms';

import { FundAccountField } from './fields/FundAccountField';
import { ReconciledField } from './fields/ReconciledField';

import { personIdentityDescription } from '@persons/templates';
import { FundEntryAction } from './FundEntryAction';
import { FundEntryUpdate } from './FundEntryUpdate';

import { EMPTY_FUND_ACCOUNT_ID, FundEntryService } from '@services/fundsService';

const DETAIL_FIELDS = ['fundAccount', 'reconciled'];

const FundEntryDetail = ({ entry }) => {
    const labelStyle = { width: '100px' };

    return (
        <div>
            <div className="grid">
                <div className="col-fixed" style={labelStyle}>
                    Fecha:
                </div>
                <div className="col">{entry.valueDate}</div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={labelStyle}>
                    Originante:
                </div>
                <div className="col">
                    {personIdentityDescription({
                        person: entry?.person,
                        stacked: true,
                        bestDocumentOnly: true,
                        emptyValue: '-.-',
                    })}
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={labelStyle}>
                    Descripción:
                </div>
                <div className="col">{entry.description || '-.-'}</div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={labelStyle}>
                    Importe:
                </div>
                <div className="col">{entry?.amount}</div>
            </div>
        </div>
    );
};

export const FundEntryReconcile = ({ entry: initialEntry, onUpdated, onCancel }) => {
    // State ------------------------------------------------------------------

    const [entry, setEntry] = useState(initialEntry);
    const [action, setAction] = useState(null);
    const [service] = useState(new FundEntryService());
    const [entryUpdated, setEntryUpdated] = useState(false);
    const [requestErrors, setRequestErrors] = useState([]);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            fundAccount: EMPTY_FUND_ACCOUNT_ID,
            reconciled: false,
        },
    });
    const fundAccount = watch('fundAccount');

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        if (entry != null) {
            setValue('fundAccount', entry?.fundAccount?.id);
            setValue('reconciled', entry.reconciled);
        }
    }, [entry, setValue]);

    // Events -----------------------------------------------------------------

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const fundAccountId =
            data?.fundAccount && data.fundAccount !== EMPTY_FUND_ACCOUNT_ID
                ? data.fundAccount
                : null;
        const request = {
            fundAccountId: fundAccountId,
            reconciled: data.reconciled,
        };
        service
            .update(entry.id, request)
            .then((response) => onUpdated && onUpdated(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = useCallback(
        (error) => {
            hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
        },
        [setError],
    );

    const onUpdateCompleted = (updated) => {
        setAction(null);
        setEntry(updated);
        setEntryUpdated(true);
    };

    const onReconcileCanceled = () => {
        // If reconcile is canceled but the entry was actually updated, we need
        // to call the onUpdated callback so that search view client is updated.
        entryUpdated ? onUpdated(entry) : onCancel();
    };

    // Rendering --------------------------------------------------------------

    if (action === FundEntryAction.UPDATE) {
        return (
            <FundEntryUpdate
                entry={entry}
                onCancel={() => setAction(null)}
                onSuccess={(updated) => onUpdateCompleted(updated)}
            />
        );
    }

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title={`Conciliar ${entry.type.label}`}
            buttons={{ executeLabel: 'Guardar' }}
            onCancel={onReconcileCanceled}
            requestErrors={requestErrors}
            style={{ width: '400px' }}
        >
            <FundEntryDetail entry={entry} />

            <Button
                type="button"
                label="Editar"
                className="p-button-link"
                onClick={() => setAction(FundEntryAction.UPDATE)}
            />

            <br />
            <hr />
            <div className="p-fluid">
                <FundAccountField
                    control={control}
                    errors={errors}
                    required={false}
                    allowEmpty={entry.incomingFund}
                    onRequestError={handleRequestError}
                    autoFocus
                />

                <ReconciledField
                    control={control}
                    errors={errors}
                    required={false}
                    fundAccount={fundAccount}
                />
            </div>
        </ModalForm>
    );
};
