import { useState } from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import { RequestMessages } from '@components/RequestMessages';
import { TimeUnitField } from '@components/fields/timeUnit';
import { TimeUnitDateField } from '@components/fields/timeUnitDate';
import { TransparentOverlay } from '@components/overlay';
import { TransactionType } from '@enums/accounting';
import { TimeUnit } from '@enums/time';
import { fromISOLocalDateString, toISOLocalDateString } from '@utils/date-utils';

import { AccountingService } from '@services/accounting';

const GENERATED_TYPES = [
    // -------------------------------------
    TransactionType.FEE_REVENUE,
    TransactionType.FEE_DISCOUNT,
    // Reports use `standing` and `not_reversed` transactions.
    // Therefore, it would be an error to include reversed items,
    // as they are already discounted from the sub-total.
    // TransactionType.FEE_REVENUE_REVERSE,
    // TransactionType.FEE_DISCOUNT_REVERSE,
    // -------------------------------------
    TransactionType.REVENUE,
    TransactionType.REVENUE_DISCOUNT,
    // -------------------------------------
    TransactionType.TOKEN_REVENUE,
    // -------------------------------------
    TransactionType.COMMISSION_INCOMING,
    // TODO: This type might be removed in the future.
    // Currently it is not used in the application, webapp
    // does not afford users to create pseudo expenses this way.
    // TransactionType.COMMISSION_OUTGOING,
    // -------------------------------------
    // TODO: This transaction type will be deleted
    TransactionType.ACCOUNT_DEBIT,
    TransactionType.ACCOUNT_CREDIT,
];

const COLLECTED_TYPES = [
    TransactionType.CASH_INCOMING_PAYMENT,
    TransactionType.FUND_INCOMING_TRANSFER,
];

const columnHeader = (period, unit) => {
    const weekExtraData = () => {
        if (unit === TimeUnit.WEEK.value) {
            return (
                <span>
                    <br /> {period.since}
                </span>
            );
        } else {
            return null;
        }
    };
    // TODO: Week number should be centered to period.display
    return (
        <>
            <span>{period.display}</span>
            {weekExtraData()}
        </>
    );
};

const reportRowsToColumns = (data, unit) => {
    const periods = data.map((period) => period.period);
    periods.forEach((period) => {
        period.header = columnHeader(period, unit);
    });

    const generatedTotals = {};
    const collectedTotals = {};
    data.forEach((period, index) => {
        const column = `col${index}`;
        generatedTotals[column] = period.summary.generated;
        collectedTotals[column] = period.summary.collected;
    });

    const makeDetails = (transactionTypes, key) =>
        transactionTypes.map((type, index) => {
            const columns = {};
            data.forEach((period, column) => {
                columns[`col${column}`] = period.details.find((row) => {
                    return row.type === type.value;
                })?.amount;
            });
            return {
                key: `${key}-${index}`,
                data: {
                    type: type.plural,
                    ...columns,
                },
            };
        });

    const generated = {
        key: '0',
        data: {
            type: 'Generado',
            header: true,
            ...generatedTotals,
        },
        children: makeDetails(GENERATED_TYPES, '0'),
    };
    const collected = {
        key: '1',
        data: {
            type: 'Cobrado',
            header: true,
            ...collectedTotals,
        },
        children: makeDetails(COLLECTED_TYPES, '1'),
    };
    const details = [generated, collected];

    return { periods, details };
};

const TIME_UNIT_OPTIONS = [
    // disable prettier
    TimeUnit.DAY,
    TimeUnit.WEEK,
    TimeUnit.MONTH,
    TimeUnit.YEAR,
];

const DEFAULT_SEARCH_PARAMS = {
    unit: TimeUnit.MONTH.value,
    count: -3,
    refDate: null,
};

export const AccountingReport = () => {
    const [service] = useState(new AccountingService());
    const [requestErrors, setRequestErrors] = useState();

    const [periods, setPeriods] = useState([]);
    const [details, setDetails] = useState([]);

    const { control, handleSubmit, formState, reset, setError, setValue, watch } = useForm({
        defaultValues: { ...DEFAULT_SEARCH_PARAMS, refDate: new Date() },
    });

    const { errors, isDirty } = formState;
    const [unit, refDate] = watch(['unit', 'refDate']);

    // Execute ----------------------------------------------------------------

    const search = (input) => {
        const processData = (data, request) => {
            const { periods, details } = reportRowsToColumns(data, request.unit);
            setPeriods(periods);
            setDetails(details);
            // Set refDate to the returned lastPeriod.since
            const lastPeriod = periods.slice(-1)[0];
            if (lastPeriod != null) {
                reset({
                    ...request,
                    refDate: fromISOLocalDateString(lastPeriod.since),
                });
            } else {
                reset({}, { keepValues: true });
            }
        };

        setRequestErrors(null);

        const request = {
            ...input,
            refDate: toISOLocalDateString(input.refDate),
        };

        service
            .getAccountingReport(request)
            .then((response) => processData(response.data, request))
            .catch((error) =>
                hookFormRequestError(error, ['unit', 'count'], setError, setRequestErrors),
            );
    };

    // Events -----------------------------------------------------------------

    const stepDate = (value) => {
        const newDate = new Date(refDate);
        switch (unit) {
            case TimeUnit.DAY.value:
                newDate.setDate(newDate.getDate() + value);
                break;
            case TimeUnit.WEEK.value:
                newDate.setDate(newDate.getDate() + value * 7);
                break;
            case TimeUnit.MONTH.value:
                newDate.setMonth(newDate.getMonth() + value);
                break;
            case TimeUnit.YEAR.value:
                newDate.setFullYear(newDate.getFullYear() + value);
                break;
            default:
                console.log(`Unhandled TimeUnit ${unit}`);
        }
        setValue('refDate', newDate);
        const searchParams = {
            unit: unit,
            count: DEFAULT_SEARCH_PARAMS.count,
            refDate: newDate,
        };
        search(searchParams);
    };

    // Rendering --------------------------------------------------------------

    const prevPeriodSelector = (
        <Button
            icon="pi pi-angle-left"
            className="p-button-rounded p-button-text"
            style={{ width: '32px' }}
            onClick={() => stepDate(-1)}
        />
    );

    const nextPeriodSelector = (
        <Button
            icon="pi pi-angle-right"
            className="p-button-rounded p-button-text"
            style={{ width: '32px' }}
            onClick={() => stepDate(1)}
        />
    );

    const rowClassName = (row) => {
        return {
            'report-item-header': row.data?.header,
            'report-item-detail': !row.data?.header,
        };
    };

    return (
        <Card>
            <RequestMessages messages={requestErrors} />
            <form onSubmit={handleSubmit(search)}>
                <div className="formgroup-inline" style={{ paddingBottom: '0.5rem' }}>
                    <TimeUnitField
                        control={control}
                        errors={errors}
                        fieldName="unit"
                        fieldLabel="Período"
                        optionLabel="period"
                        options={TIME_UNIT_OPTIONS}
                        style={{ width: '8rem' }}
                    />
                    <TimeUnitDateField
                        control={control}
                        errors={errors}
                        fieldName="refDate"
                        fieldLabel="Último"
                        unit={unit}
                        // Setting with constraints also controls the drop-down
                        // width, which is a problem: The input control is very
                        // large for the required input normally, but reducing
                        // its width makes the dropdown very thin and hard to use.
                        // style={{ width: '10rem' }}
                        showButtonBar
                        // TODO: Consider adding this options
                        // showIcon
                        // touchUI
                    />
                    <Button label="Buscar" icon="pi pi-search" />
                </div>
            </form>
            <TransparentOverlay isOn={isDirty}>
                <TreeTable
                    value={details}
                    scrollable
                    frozenWidth="180px"
                    className="accounting-report-table"
                    rowClassName={rowClassName}
                    emptyMessage="Sin datos"
                >
                    <Column
                        field="type"
                        // header="Concepto"
                        header={
                            <div>
                                {prevPeriodSelector}
                                {nextPeriodSelector}
                            </div>
                        }
                        expander
                        frozen
                        style={{ width: '180px' }}
                        className="report-frozen-column"
                    />
                    {periods.length &&
                        periods.map((period, index) => (
                            <Column
                                key={index}
                                header={period.header}
                                field={`col${index}`}
                                className="table-column-money"
                            />
                        ))}
                </TreeTable>
            </TransparentOverlay>
        </Card>
    );
};
