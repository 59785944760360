export const PersonAction = Object.freeze({
    CREATE: 'create',
    CHANGE_NAME: 'change-name',
    CHANGE_DNI: 'change-dni',
    CHANGE_CUIT: 'change-cuit',
    CHANGE_PHONE_NUMBER: 'change-phone-number',
    CHANGE_EMAIL: 'change-email',
    CHANGE_DOB: 'change-dob',
    CHANGE_ADDRESS: 'change-address',
    CHANGE_NOTES: 'change-notes',
});
