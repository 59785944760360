import { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { hookFormRequestError } from '@custom/hook-form';

import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

import { DateTimeField, DescriptionField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { PersonPath } from '@persons/routes';
import {
    fromISOLocalDateString,
    toISODateTimeStringWithoutTimeZoneName,
    toMidnightTemporalInSiteTimeZone,
} from '@utils/date-utils';
import { strippedOrNull } from '@utils/string-utils';

import { TokenService } from '@services/tokenService';

const DETAIL_FIELDS = ['usedAt', 'description'];

export const TokenPackUsed = ({ pack }) => `${pack.used === 0 ? '-.-' : pack.used} / ${pack.count}`;

const TokenUseCreate = ({ personId, pack, onSuccess, ...rest }) => {
    const from = useLocation().state?.from;
    const history = useHistory();
    const [service] = useState(new TokenService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        watch,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            packId: pack.id,
            usedAt: [],
            comment: '',
        },
    });

    const usedAt = watch('usedAt');

    const [usedAtRules, usedAtMessages, usedAtProps] = useMemo(() => {
        return [
            {
                required: true,
                validate: {
                    maxCount: (values) => {
                        return values.length <= pack.balance;
                    },
                    minCount: (values) => {
                        return values.length > 0;
                    },
                },
            },
            {
                maxCount: `Excede cantidad disponible de tokens: ${pack.balance}`,
                minCount: 'Debe seleccionar al menos una fecha',
                required: 'Debe seleccionar al menos una fecha',
            },
            pack?.since
                ? {
                      minDate: fromISOLocalDateString(pack.since),
                      maxDate: fromISOLocalDateString(pack.until),
                      viewDate: fromISOLocalDateString(pack.since),
                  }
                : // When pack does not have a constraint period, then
                  // current month is displayed and user can select any
                  // any date as a `usedAt` value.
                  {},
        ];
    }, [pack]);

    // Execute ----------------------------------------------------------------

    const removeDate = (indexToRemove) => {
        const newValues = usedAt.filter((_, index) => index !== indexToRemove);
        setValue('usedAt', newValues, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
        });
    };

    const ChipsRender = ({ usedAt, onRemove }) => {
        return (
            <div>
                {usedAt.map((date, index) => {
                    return (
                        <Chip
                            key={index}
                            label={date.toLocaleDateString('es-AR', {
                                year: 'numeric',
                                month: '2-digit',
                                day: 'numeric',
                            })}
                            removable
                            onRemove={() => onRemove(index)}
                            className="mr-2 mb-2"
                        />
                    );
                })}
            </div>
        );
    };

    const onExecute = (data) => {
        const createRequest = () => {
            const comment = strippedOrNull(data.comment);
            // For all dates, zero hour in site time zone (fixed to ARG - UTC-3:00)
            return data.usedAt.map((date) => {
                return {
                    packId: pack.id,
                    usedAt: toISODateTimeStringWithoutTimeZoneName(
                        toMidnightTemporalInSiteTimeZone(date),
                    ),
                    comment: comment,
                };
            });
        };

        clearErrors();
        setRequestErrors(null);
        const request = createRequest();
        service
            .use_create(request)
            .then((response) => {
                if (onSuccess != null) {
                    onSuccess(response.data);
                } else {
                    const nextStep = from || generatePath(PersonPath.read.tokens, { id: personId });
                    history.replace(nextStep);
                }
            })
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    return (
        <ModalForm
            className="token-use-create"
            title={`Consumir ${pack?.type?.name}`}
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            buttons={{
                executeLabel: 'Consumir',
            }}
            {...rest}
        >
            <div className="p-fluid">
                <div className="grid">
                    <div className="col">
                        <span className="font-bold">Período:</span> {pack.timePeriod}
                    </div>
                    <div className="col">
                        <span className="font-bold">Disponibles:</span> {pack.balance}
                    </div>
                </div>
                <hr />
                {/* <FieldErrorMessages name="usedAt" errors={errors} customMessages={usedAtMessages} /> */}
                <DateTimeField
                    control={control}
                    errors={errors}
                    rules={usedAtRules}
                    customMessages={usedAtMessages}
                    fieldLabel="Días de consumo (pueden ser múltiples días)"
                    fieldName="usedAt"
                    dateFormat="d/m/yy"
                    hourFormat="24"
                    showTime={false}
                    showIcon={false}
                    inline
                    selectionMode="multiple"
                    {...usedAtProps}
                />
                <ChipsRender usedAt={usedAt} onRemove={removeDate} />
                {/* TODO: Consider making this `required` here, because we don´t
                have context of why the token is consumed. That would not happen
                if the server consumes a token creating an EventAttendance. */}
                <br />
                <DescriptionField
                    control={control}
                    errors={errors}
                    fieldName="comment"
                    fieldLabel="Comentario (opcional)"
                />
            </div>
        </ModalForm>
    );
};

export const TokenPackBalance = ({ pack, onChanged, style = null, balanceLabel = null }) => {
    const [usePack, setUsePack] = useState(false);

    const doChanged = (data) => {
        setUsePack(false);
        if (onChanged != null) {
            onChanged(data);
        }
    };

    const doCancel = () => {
        setUsePack(false);
    };

    const label = balanceLabel ? `${balanceLabel}: ` : '';

    return (
        <div>
            <div className="token-pack-balance" style={style}>
                {label}
                {pack.balance === 0 ? '-.-' : pack.balance}
                {pack.balance > 0 && !pack.isRevoked && (
                    <Button
                        className="p-button-rounded"
                        icon="pi pi-bolt"
                        onClick={(event) => {
                            // console.log('Button.onClick');
                            // Not required, it does not propagate
                            // event.stopPropagation();
                            setUsePack(true);
                        }}
                    />
                )}
            </div>
            {usePack && (
                <TokenUseCreate
                    pack={pack}
                    onSuccess={doChanged}
                    onCancel={doCancel}
                    // --------------------------------------------------------
                    // Exiting inputs with ESC closes the dialog too frequently
                    closeOnEscape={false}
                    // --------------------------------------------------------
                    // If false (default), clicking outside the dialog triggers
                    // the underlying div event, navigating to the detail page.
                    dismissableMask={true}
                    // ========================================================
                    // TODO: Any click on the dialog and clickUp outside of it
                    // causes the underlying div.onclick navigation to trigger.
                    // We weren't able to prevent that from happening here, so
                    // the actual parent checks if the target is the mask and
                    // ignores navigation.
                    // --------------------------------------------------------
                    // onMouseDown={(event) => {
                    //     console.log('Dialog.onMouseDown');
                    // }}
                    // onMaskClick={(event) => {
                    //     // This is the last event to trigger navigation, when
                    //     // doing a mouseUp outside the dialog (i.e. on mask).
                    //     console.log('Dialog.onMaskClick');
                    // }}
                    // onMouseUp={(event) => {
                    //     console.log('Dialog.onMouseUp');
                    // }}
                    // --------------------------------------------------------
                    // If not set, clicking anywhere inside the dialog triggers
                    // the underlying div event, navigating to the detail page.
                    onClick={(event) => {
                        // console.log('Dialog.onClick');
                        event.stopPropagation();
                    }}
                />
            )}
        </div>
    );
};
