import { useCallback, useEffect, useState } from 'react';

import { generatePath, Link, useHistory, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { LinkButton, RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate } from '@utils/money';
import { appClickOpenPush } from '@utils/nav-utils';

import { TokenPackDetail, TokenService } from '@services/tokenService';
import { TokensPath } from '@tokens/routes';
import { packDescription } from '@tokens/templates';
import { TokenPackBalance, TokenPackUsed } from '@tokens/uses/create';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const PersonTokenPackList = () => {
    const params = useParams();
    const history = useHistory();
    const [service] = useState(new TokenService());

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Search results
    const [items, setItems] = useState([]);
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        (first, page, rows) => {
            const searchParams = {
                pageSize: rows,
                page: page + 1,
                personId: params.id,
                detail: TokenPackDetail.PERSON,
            };
            setRequestMessages(null);
            service
                .packs(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service, params.id],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(0, 0, rows);
    }, [search, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(event.first, event.page, event.rows);
    };

    const refresh = () => {
        search(first, page, rows);
    };

    const rowClick = ({ data, originalEvent }) => {
        // console.log(`rowClick - target: ${originalEvent.target.className}`);
        // If the original target is the TokenUse modal dialog mask, ignore it
        // and do not execute any navigation. See code there, we were unable to
        // prevent onClick from bubbling up here when the mask was clicked.
        const target = originalEvent.target.className;
        if (target.includes('p-dialog-mask')) return;
        const url = generatePath(TokensPath.TokenPack.item, { id: data.id });
        appClickOpenPush(url, originalEvent, history);
    };

    // Rendering --------------------------------------------------------------

    const rowClassName = (pack) => (pack.isRevoked ? 'revoked' : '');

    const descriptionTemplate = (pack) => (
        <div className="token-pack">
            <div className="pack-description">{packDescription(pack)}</div>
            <div className="period">Período: {pack.timePeriod}</div>
            <div className="issued-at">{isoLocalDateTimeTemplate(pack, 'issuedAt')}</div>
            {/* {pack.isRevoked && <span> | ANULADO</span>} */}
        </div>
    );

    const usedTemplate = (pack) => <TokenPackUsed pack={pack} />;

    const balanceTemplate = (pack) => (
        <TokenPackBalance pack={pack} style={{ justifyContent: 'center' }} onChanged={refresh} />
    );

    const header = (
        <div className="split-table-header">
            <div className="flex align-items-center gap-3">
                <LinkButton
                    to={{
                        pathname: generatePath(TokensPath.TokenPack.create),
                        search: '?' + new URLSearchParams({ personId: params.id }).toString(),
                        // We don't use this, because the TokenIssued.create view allows to
                        // change the person the token is issued to. So we use that actual
                        // person's id to return here instead.
                        // state: { from: location },
                    }}
                >
                    <Button label="Generar Tokens" />
                </LinkButton>
                <Link
                    to={{
                        pathname: generatePath(TokensPath.TokenUse.search),
                        search: '?' + new URLSearchParams({ personId: params.id }).toString(),
                    }}
                >
                    Tokens Consumidos
                </Link>
            </div>
            <RefreshButton onRefresh={refresh} />
        </div>
    );

    return (
        <Card className="person-token-pack-list">
            <RequestMessages messages={requestMessages} />

            <DataTable
                header={header}
                value={items}
                dataKey="id"
                emptyMessage="No hay tokens"
                rowClassName={rowClassName}
                // --------------------------------------------------------------------------
                selectionMode="single"
                onRowClick={rowClick}
                // --------------------------------------------------------------------------
                lazy
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                onPage={onPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column header="Packs" body={descriptionTemplate} />
                <Column header="Consumidos" body={usedTemplate} className="table-column-center" />
                <Column
                    header="Disponibles"
                    body={balanceTemplate}
                    className="table-column-center"
                />
            </DataTable>
        </Card>
    );
};
