import { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import { FieldErrorMessages, errorClassName } from '@components/field-error-messages';
import { Controller } from 'react-hook-form';

import { EMPTY_FUND_ACCOUNT_ID, FundEntryService } from '@services/fundsService';

export const FundAccountField = ({
    // @prettier:off
    control,
    errors,
    fieldName = 'fundAccount',
    fieldLabel = 'Cuenta',
    onRequestError,
    required = true,
    allowEmpty = false,
    ...rest
}) => {
    const [service] = useState(new FundEntryService());
    const [options, setOptions] = useState([]);

    useEffect(() => {
        service
            .getAccountOptions()
            .then((response) => {
                const newOptions = response?.data?.results || [];
                if (allowEmpty) {
                    newOptions.push({ id: EMPTY_FUND_ACCOUNT_ID, name: 'Sin cuenta de destino' });
                }
                setOptions(newOptions);
            })
            .catch((error) => onRequestError && onRequestError(error));
    }, [service, allowEmpty, onRequestError]);

    return (
        <div className="field">
            <label htmlFor={fieldName} className={errorClassName(errors[fieldName])}>
                {fieldLabel}
                {required ? ' *' : null}
            </label>

            <Controller
                name={fieldName}
                control={control}
                rules={{
                    required: required,
                }}
                render={({ field, fieldState }) => (
                    <Dropdown
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        options={options}
                        dataKey="id"
                        optionValue="id"
                        optionLabel="name"
                        // Component label not updated when value is cleared
                        // https://github.com/primefaces/primereact/issues/4113
                        // showClear={true}
                        // Workaround: Add a no-option option:
                        // { id: null, name: 'Sin cuenta destino' }
                        placeholder="Seleccionar"
                        emptyMessage="Sin cuentas configuradas"
                        className={classNames({ 'p-invalid': fieldState.error })}
                        {...rest}
                    />
                )}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

FundAccountField.displayName = 'FundAccountField';
