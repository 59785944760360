import { PersonCreate } from './create';
import { PersonRead } from './read';
import { PersonSearch } from './search/index';

const BASE_PATH = '/persons';
const READ_PATH = '/persons/:id';

export const PersonPath = Object.freeze({
    search: BASE_PATH,
    create: BASE_PATH + '/create',
    read: {
        default: READ_PATH,
        tokens: READ_PATH + '/tokens',
    },
});

export const routesInfo = [
    {
        path: PersonPath.search,
        title: 'Personas',
        exact: true,
        component: PersonSearch,
    },
    {
        path: PersonPath.create,
        title: 'Nueva Persona',
        exact: false,
        component: PersonCreate,
    },
    {
        path: PersonPath.read.default,
        title: 'Persona',
        exact: false,
        component: PersonRead,
    },
];
