import { useState } from 'react';

import { generatePath, useHistory } from 'react-router-dom';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import {
    AddressField,
    CUITField,
    DNIField,
    DOBField,
    EmailField,
    FirstNameField,
    LastNameField,
    NotesField,
    PhoneNumberField,
    unmaskedCUIT,
    unmaskedDNI,
} from '@components/fields';
import { ModalPageForm } from '@components/forms/ModalPageForm';
import { toISOLocalDateString } from '@utils/date-utils';
import { StringUtils } from '@utils/string-utils';

import { PersonService } from '@services/personService';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';
import { PersonPath } from './routes';

const initPerson = {
    firstName: '',
    lastName: '',
    dni: '',
    cuit: '',
    // phoneNumber: '343',
    phoneNumber: '',
    email: '',
    dob: null,
    address: '',
    notes: '',
};

const DETAIL_FIELDS = Object.getOwnPropertyNames(initPerson);

// const COLUMN_BREAK = true;
const COLUMN_BREAK = false;

export const PersonCreate = ({ from = null }) => {
    const [service] = useState(new PersonService());
    const history = useHistory();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: { ...initPerson },
    });
    const [requestErrors, setRequestErrors] = useState([]);

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const { address, ...personData } = data;
        const request = {
            ...personData,
            dni: unmaskedDNI(data.dni),
            cuit: unmaskedCUIT(data.cuit),
            dob: toISOLocalDateString(data.dob),
            addresses: StringUtils.isEmpty(address) ? null : [{ value: address }],
        };
        service
            .create(request)
            .then((response) => {
                console.log(response);
                const personId = response?.data?.id;
                const nextStep = from || generatePath(PersonPath.read.default, { id: personId });
                history.replace(nextStep);
            })
            .catch(handleRequestError);
    };

    const handleRequestError = (error) => {
        if (error?.response?.data) {
            const { addresses, ...restData } = error.response.data;
            if (addresses) {
                restData['address'] = addresses[0].value;
                error.response.data = restData;
            }
        }
        hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
    };

    return (
        <ModalPageForm
            className="person-create"
            from={from}
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            executeButtonsProps={{
                executeLabel: 'Crear',
                cancelLabel: 'Volver',
            }}
        >
            <Card>
                <div className="formgrid grid p-fluid">
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <FirstNameField register={register} errors={errors} autoFocus />
                    </div>
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <LastNameField register={register} errors={errors} />
                    </div>
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <DNIField required={false} control={control} errors={errors} />
                    </div>
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <CUITField required={false} control={control} errors={errors} />
                    </div>
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <PhoneNumberField register={register} errors={errors} />
                    </div>
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <EmailField register={register} errors={errors} />
                    </div>
                    <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        <DOBField control={control} errors={errors} />
                    </div>
                    {/* <div className={classNames('field col-12', { 'md:col-6': COLUMN_BREAK })}>
                        Edad.indeterminada
                    </div> */}
                    <div className="field col-12">
                        <AddressField
                            control={control}
                            errors={errors}
                            fieldName="address"
                            rows={2}
                        />
                    </div>
                    <div className="field col-12">
                        <NotesField control={control} errors={errors} rows={2} />
                    </div>
                </div>

                {/* <div className="p-fluid">
                    <FirstNameField register={register} errors={errors} autoFocus />
                    <LastNameField register={register} errors={errors} />
                    <DNIField required={false} control={control} errors={errors} />
                    <CUITField required={false} control={control} errors={errors} />
                    <PhoneNumberField register={register} errors={errors} />
                    <EmailField register={register} errors={errors} />
                    <DOBField control={control} errors={errors} />
                    <AddressField control={control} errors={errors} fieldName="address" rows={2} />
                    <NotesField control={control} errors={errors} />
                </div> */}
            </Card>
        </ModalPageForm>
    );
};
