import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { Wizard } from './Wizard';

// Sourced from
// https://chatgpt.com/c/678ab1b7-ce28-8005-92b2-290078698594

export const WizardDemo = () => {
    return (
        <div>
            <h2>WizardDemo</h2>
            <Router>
                <Switch>
                    <Route
                        path="/demos/dashboard"
                        component={() => <h2>Welcome to the Dashboard</h2>}
                    />
                    <Route path="/demos/wizard" component={Wizard} />
                    <Route path="*" component={() => <h2>Not Found</h2>} />
                </Switch>
            </Router>
        </div>
    );
};
