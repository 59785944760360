import { useCallback, useState } from 'react';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';

import { RequestMessages } from '@components/RequestMessages';
import { AmountField, DescriptionField, PersonField, ValueDateField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { fromISOLocalDateString, toISOLocalDateString } from '@utils/date-utils';
import { parseMoney } from '@utils/money';

import { CanceledStatus } from '@enums/index';
import { FundEntryService } from '@services/fundsService';

const DETAIL_FIELDS = ['valueDate', 'person', 'description', 'amount'];

export const getWarnings = (entry) => {
    const warnings = [];
    if (entry.receipt) {
        warnings.push({
            sticky: true,
            severity: 'warn',
            summary: 'Recibo existente será anulado.',
            closable: false,
        });
    }
    if (CanceledStatus.anyCancelations(entry)) {
        warnings.push({
            sticky: true,
            severity: 'warn',
            summary: 'Cancelaciones asociadas serán anuladas.',
            closable: false,
        });
    }
    return warnings;
};

export const FundEntryUpdate = ({ entry, onSuccess, onCancel }) => {
    const [service] = useState(new FundEntryService());
    const [requestErrors, setRequestErrors] = useState([]);
    const [revokeWarnings] = useState(getWarnings(entry));
    const [revokeRequired] = useState(revokeWarnings.length > 0);
    const [revokeConfirmed, setRevokeConfirmed] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            // WARNING: person is edited in a list like component
            person: entry?.person ? [entry.person] : null,
            valueDate: fromISOLocalDateString(entry?.valueDate),
            description: entry?.description || '',
            amount: parseMoney(entry.amount),
        },
    });

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const request = {
            personId: data?.person?.[0]?.id || null,
            valueDate: toISOLocalDateString(data.valueDate),
            description: data.description,
            amount: data.amount,
            forceRevoke: revokeConfirmed,
        };
        service
            .update(entry.id, request)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = useCallback(
        (error) => {
            hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
        },
        [setError],
    );

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title={`Editar ${entry.type.label}`}
            buttons={{
                executeDisabled: revokeRequired && !revokeConfirmed,
                executeLabel: revokeRequired ? 'Anular y crear nueva' : 'Guardar',
            }}
            onCancel={onCancel}
            requestErrors={requestErrors}
            style={{ width: '400px' }}
        >
            <div className="p-fluid">
                <ValueDateField control={control} errors={errors} />

                <PersonField
                    control={control}
                    errors={errors}
                    required={!entry.incomingFund}
                    fieldLabel={entry.incomingFund ? 'Originante' : 'Destinatario'}
                    autoFocus
                    onRequestError={handleRequestError}
                />

                <DescriptionField control={control} errors={errors} />

                <AmountField control={control} errors={errors} />

                {revokeRequired && (
                    <>
                        <hr />
                        <p style={{ fontWeight: 600, fontSize: '1.25rem' }}>
                            {`¿Confirma editar ${entry.type.label}?`}
                        </p>
                        <RequestMessages messages={revokeWarnings} />
                        <br />
                        <div className="field-checkbox">
                            <Checkbox
                                inputId="revokeConfirmation"
                                checked={revokeConfirmed}
                                onChange={(e) => setRevokeConfirmed(e.checked)}
                            />
                            <label htmlFor="revokeConfirmation">
                                Anular y crear una nueva copia editada
                            </label>
                        </div>
                    </>
                )}
            </div>
        </ModalForm>
    );
};
