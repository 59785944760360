import { Dialog } from 'primereact/dialog';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { RequestMessages } from '@components/RequestMessages';

export const ModalForm = ({
    title,
    onSubmit,
    onCancel,
    children,
    buttons,
    requestErrors,
    autoComplete = 'off', // Off by default, but can be overridden
    ...rest
}) => {
    const footer = <ExecuteCancelButtons onCancel={onCancel} onExecute={onSubmit} {...buttons} />;

    // Nesting like this form does not get submitted pressing enter on Input
    // < form >
    //     <Dialog />
    // </form >

    // Nesting like this form does get submitted on every ENTER and closes,
    // unless we explicitly handle the event and prevent default submission.
    // Nesting like this is required for autoComplete='off' to work.
    // <Dialog>
    //      <form/>
    // </Dialog>

    // Chrome Browser seems to ignore autoComplete=off in components
    // https://bugs.chromium.org/p/chromium/issues/detail?id=587466

    // But it works when being set at form level:
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion

    const handleSubmit = (event) => {
        // Prevents form submission when uses types ENTER key on ant input.
        // All submissions are to be handled with ExecuteButtons.onExecute.
        // https://github.com/react-hook-form/react-hook-form/discussions/2549
        event.preventDefault();
    };

    return (
        <Dialog {...rest} modal visible={true} header={title} footer={footer} onHide={onCancel}>
            <form onSubmit={handleSubmit} autoComplete={autoComplete}>
                <RequestMessages messages={requestErrors} />
                {children}
            </form>
        </Dialog>
    );
};
