import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useWizard } from './WizardContext';

export const Step = ({ step, nextStep, prevStep }) => {
    const history = useHistory();
    const { formData, updateFormData } = useWizard();
    // const location = useLocation();

    // Redirect to the previous step if the current step has not been completed
    useEffect(() => {
        if (step > 1 && !formData[`step${step - 1}Completed`]) {
            history.replace(prevStep); // Redirect to the previous step
        }
    }, [step, formData, history, prevStep]);

    const handleNext = () => {
        // Mark the current step as completed
        updateFormData({ [`step${step}Completed`]: true });
        history.replace(nextStep); // Proceed to the next step
    };

    const handlePrev = () => {
        history.replace(prevStep); // Go to the previous step
    };

    return (
        <div>
            <h2>Step {step}</h2>
            <input
                type="text"
                value={formData[`input${step}`]}
                onChange={(e) => updateFormData({ [`input${step}`]: e.target.value })}
            />
            <div>
                {prevStep && <button onClick={handlePrev}>Back</button>}
                {nextStep && <button onClick={handleNext}>Next</button>}
            </div>
        </div>
    );
};
