import { createContext, useContext, useState } from 'react';

// Context to manage the form data
const WizardContext = createContext();

export const useWizard = () => useContext(WizardContext);

export const WizardProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        step1Completed: false,
        step2Completed: false,
        step3Completed: false,
        input1: '',
        input2: '',
        input3: '',
    });

    const updateFormData = (newData) => {
        setFormData((prevData) => ({ ...prevData, ...newData }));
    };

    return (
        <WizardContext.Provider value={{ formData, updateFormData }}>
            {children}
        </WizardContext.Provider>
    );
};
