import { InputTextarea } from 'primereact/inputtextarea';

import { useController } from 'react-hook-form';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';

export const NotesView = ({ notes }) => {
    return <div style={{ whiteSpace: 'pre-line' }}>{notes}</div>;
};

export const NotesField = ({
    // @prettier-off
    fieldName = 'notes',
    fieldLabel = 'Notas',
    control,
    errors,
    required = false,
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={invalidClassName(fieldState.error)}>
                    {fieldLabel}
                    {required ? ' *' : ''}
                </label>
            )}

            <InputTextarea
                id={field.name}
                value={field.value}
                name={field.name}
                autoComplete="off"
                onChange={(e) => field.onChange(e.target.value)}
                className={invalidClassName(fieldState.error)}
                rows={5}
                cols={30}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

NotesField.displayName = 'NotesField';
