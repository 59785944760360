import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useWizard } from './WizardContext';

export const FinalStep = () => {
    const history = useHistory();
    const { formData, updateFormData } = useWizard();

    useEffect(() => {
        // Guard to prevent direct access to the final step
        if (!formData.step3Completed) {
            history.replace('/wizard'); // Redirect to the beginning of the wizard
        }
    }, [formData, history]);

    const handleComplete = () => {
        // Handle form submission or completion logic here
        console.log('Form Data:', formData);

        // Reset the wizard state
        updateFormData({
            step1Completed: false,
            step2Completed: false,
            step3Completed: false,
            input1: '',
            input2: '',
            input3: '',
        });

        // Redirect to another page (e.g., dashboard)
        // Use replace to clear history stack
        // Backspacing from next destination will NOT return to the wizard.
        // TODO: Make sure this is what we want, because from other steps,
        // backspacing redirects us to the beginning of the wizard.
        history.replace('/persons');
    };

    return (
        <div>
            <h2>Final Step</h2>
            <button onClick={handleComplete}>Complete</button>
        </div>
    );
};
